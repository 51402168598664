import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const StyledDiv = styled.div`
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background-color: ${({backgroundColor, theme}) => backgroundColor || theme.colors.background};
    color: ${({color, theme}) => color || theme.colors.text};
    ${({paddingLeft}) => paddingLeft ? `padding-left: ${paddingLeft};` : ""}
    ${({paddingRight}) => paddingRight ? `padding-right: ${paddingRight};` : ""}
    ${({paddingTop}) => paddingTop ? `padding-top: ${paddingTop};` : ""}
    ${({paddingBottom}) => paddingBottom ? `padding-bottom: ${paddingBottom};` : ""}
    ${({marginLeft}) => marginLeft ? `margin-left: ${marginLeft};` : ""}
    ${({marginRight}) => marginRight ? `margin-right: ${marginRight};` : ""}
    ${({marginTop}) => marginTop ? `margin-top: ${marginTop};` : ""}
    ${({marginBottom}) => marginBottom ? `margin-bottom: ${marginBottom};` : ""}
    ${({shadow, theme}) => shadow ? `box-shadow: ${theme.shadow};` : ""}
    ${({verticalAlign}) => verticalAlign? `align-items: ${verticalAlign};` : ""}
    ${({horizontalAlign}) => horizontalAlign? `justify-content: ${horizontalAlign};` : ""}
    ${({breakpoint, responsiveStyle}) => breakpoint ? `@media (max-width: ${breakpoint}) {${responsiveStyle}}` : ""}
    transition: transform 400ms cubic-bezier(.455, .03, .515, .955), box-shadow 200ms cubic-bezier(.455, .03, .515, .955);

    &:hover {
        transform: translate(0px, -2px);
        box-shadow: ${({theme}) => theme.hoverShadow};
    }
`
const StyledWrapper = styled.div``

const StyledLink = styled(Link)`
    text-decoration: none;
    color: ${({theme}) => theme.colors.text};
    width:100%;
`
const Category = styled.div`
    color: ${({color, theme}) => color || theme.colors.extraSubtleText};
    text-transform: uppercase;
    font-size: 12px;
    transition: color 200ms cubic-bezier(.455, .03, .515, .955);

    ${StyledDiv}:hover & {
        color: ${({theme}) => theme.colors.secondary};
    }
`

const Title = styled.h2`
    padding-bottom: 15px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: ${({color, theme}) => color || theme.colors.text};
    font-size: 24px;
    line-height: 32px;
`
export const CardLinkHeader = ({children, category, color, ...rest}) => (
    <StyledWrapper color={color} {...rest}>
        <Category color={color}>{category}</Category>
        <Title color={color}>{children}</Title>
    </StyledWrapper>
)

export default ({ children, to, ...rest }) => (
    <StyledLink to={to}>
        <StyledDiv {...rest}>
            {children}               
        </StyledDiv>
    </StyledLink>
)