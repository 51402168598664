import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    ${({verticalAlign}) => verticalAlign? `align-items: ${verticalAlign};` : ""}
    ${({horizontalAlign}) => horizontalAlign? `justify-content: ${horizontalAlign};` : ""}
    ${({textAlign}) => textAlign ? `text-align: ${textAlign};` : ""}
    ${({maxWidth}) => maxWidth ? `max-width: ${maxWidth};` : ""}
    ${({minWidth}) => minWidth ? `min-width: ${minWidth};` : ""}
    ${({breakpoint, responsiveStyle}) => breakpoint ? `@media (max-width: ${breakpoint}) {${responsiveStyle}}` : ""}
    ${({marginLeft}) => marginLeft ? `margin-left: ${marginLeft};` : ""}
    ${({marginRight}) => marginRight ? `margin-right: ${marginRight};` : ""}
    ${({marginTop}) => marginTop ? `margin-top: ${marginTop};` : ""}
    ${({marginBottom}) => marginBottom ? `margin-bottom: ${marginBottom};` : ""}
`

const FlexColumnWrapper = ({children, ...rest}) => (
        <Wrapper {...rest}>
            {children}
        </Wrapper>
)
export default FlexColumnWrapper