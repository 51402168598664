import React from "react"
import Wrapper from "./wrapper"
import Section, { SectionHeader } from "./section"
import Button, { ButtonWrapper } from "./button"
import ExternalLink from "./externallink"

import supportWave from "../images/grayfade.svg"

export default () => (
    <Wrapper
        backgroundImage={`url(${supportWave})`}
        backgroundSize={`100% auto`}
        backgroundPosition={`center top`}
        backgroundRepeat={`no-repeat`}
    >
        <Section
            paddingTop="10vh" 
            paddingBottom="10vh" 
        >
            <SectionHeader
                title="We're here to help."
            >
                We do our best to respond to each request with a personalized reply within 1 - 2 business days.
            </SectionHeader>
            <ButtonWrapper>
                <ExternalLink to="mailto:support@vimkit.com">
                    <Button secondary={true}>Contact Support</Button>
                </ExternalLink>
            </ButtonWrapper>
        </Section>
    </Wrapper>
)