import React from "react"
import SiteLayout from "../../components/sitelayout"
import Header from "../../components/header"
import SEO from "../../components/seo"
import Card, { CardPanel } from "../../components/card"
import Wrapper from "../../components/wrapper"
import FlexRowWrapper from "../../components/flexrowwrapper"
import Section from "../../components/section"
import Button, { ButtonWrapper } from "../../components/button"
import Vector from "../../components/vector"
import CardLink, { CardLinkHeader } from "../../components/cardlink"
import SupportCta from "../../components/supportcta"

import { useTheme } from "../../services/theme"

import heroBlob from "../../images/lightblueblob.svg"
import guideDoodle from "../../images/guidedoodle.svg"

const HelpOverview = () => {
    const theme = useTheme()
    return (
        <SiteLayout>
            <SEO title="Help Center"/>
            <Wrapper
                backgroundImage={`url(${heroBlob})`}
                backgroundSize={`auto 80%`}
                backgroundPosition={`top left`}
                backgroundRepeat={`no-repeat`}
            >
                <Section>
                    <Header
                        title="How can we help you today?"
                        subTitle="FAQs and guides to use Vimkit on your website."
                        paddingTop="40px"
                        paddingBottom="40px"
                    />
                    <FlexRowWrapper verticalAlign="center">
                        <Vector src={guideDoodle} 
                            paddingLeft="15%" 
                            paddingTop="5%" 
                            paddingBottom="5%" 
                            maxWidth="35%" 
                            breakpoint={theme.breakpoints.mobile} 
                            responsiveStyle="display:none;"
                        />
                        <CardLink 
                            shadow={true} 
                            marginLeft="5%" 
                            marginRight="15%" 
                            paddingLeft="5%"
                            paddingRight="5%"
                            paddingTop="5%"
                            paddingBottom="5%"
                            breakpoint={theme.breakpoints.mobile} 
                            responsiveStyle="margin-left: auto; margin-right: auto;"
                            backgroundColor={theme.colors.primary}
                            color={theme.colors.inverseText}
                            to="/help/guides/hubspot-webflow-form-tool"
                        >
                            <CardLinkHeader category="Guide" color={theme.colors.inverseText}>
                                Using Webflow forms with Hubspot
                            </CardLinkHeader>
                            A step-by-step tutorial to connect Webflow forms to Hubspot using Vimkit's free tool.
                            <ButtonWrapper align="left" paddingTop="30px">
                                <Button secondary={true} noTransform={true}>
                                    Read &#8594;
                                </Button>
                            </ButtonWrapper>
                        </CardLink>
                    </FlexRowWrapper>
                </Section>
                <Section>
                    <CardPanel marginTop="50px" marginBottom="70px">
                        <Card 
                            key="faq-1"
                            to="/help/websites/webflow-code-snippet"
                            category="FAQ"
                            title="How do I add Vimkit to a Webflow site?"
                            callToAction="See Answer &#8594;"
                        />
                        <Card 
                            key="faq-2"
                            to="/help/integrations/hubspot-connect"
                            category="FAQ"
                            title="How do I connect a Hubspot account to Vimkit?"
                            callToAction="See Answer &#8594;"
                        />
                        <Card 
                            key="faq-3"
                            to="/help/forms/hubspot-embed-code"
                            category="FAQ"
                            title="How do I add a Hubspot form to Vimkit using the form's embed code?"
                            callToAction="See Answer &#8594;"
                        />
                        <Card 
                            key="faq-4"
                            to="/help/forms/webflow-custom-attribute"
                            category="FAQ"
                            title="How do I add the Vimkit custom attribute to a Webflow form?"
                            callToAction="See Answer &#8594;"
                        />
                        <Card 
                            key="faq-5"
                            to="/help/integrations/hubspot-disconnect"
                            category="FAQ"
                            title="How do I disconnect a Hubspot account?"
                            callToAction="See Answer &#8594;"
                        />
                        <Card 
                            key="faq-6"
                            to="/help/forms/hubspot-remove"
                            category="FAQ"
                            title="How do I remove a Hubspot form?"
                            callToAction="See Answer &#8594;"
                        />
                    </CardPanel>
                </Section>
            </Wrapper>
            <SupportCta/>
        </SiteLayout>
    )
}
export default HelpOverview